module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'Paul Von Zimmerman', // Navigation and Site Title
  siteTitleAlt: 'Paul | Portfolio', // Alternative Site title for SEO
  siteTitleShort: 'Paul | Portfolio', // short_name for manifest
  siteHeadline: 'Creating games', // Headline for schema.org JSONLD
  siteUrl: 'https://games.paulvonzimmerman.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/paul.png', // Used for SEO and manifest
  siteDescription: "Paul Von Zimmerman's game development examples",
  author: 'pcvonz', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@pcvonz', // Twitter Username
  ogSiteName: 'Paul Von Zimmerman', // Facebook Site Name
  ogLanguage: 'en_US', // og:language
  googleAnalyticsID: '',

  // Manifest and Progress color
  themeColor: '#3498DB',
  backgroundColor: '#2b2e3c',

  // Your information
  avatar: '/paul.png',
  name: 'Paul Von Zimmerman',
  location: 'Tacoma, WA',
  socialMedia: [
    {
      url: 'http://blog.endingthealphabet.xyz/',
      name: 'Blog',
    },
    {
      url: 'https://twitter.com/pcvonz?lang=en',
      name: 'Twitter',
    },
    {
      url: 'https://github.com/pcvonz',
      name: 'Github',
    },
    {
      url: '/resume_2019.pdf',
      name: 'Résumé',
    },
  ],
}
